<template>
    <div>
        <section class="welcome-area">
            <!-- ***** Wellcome Area Background Start ***** -->
            <div class="welcome-bg" data-bg="../images/photos/welcome.jpg">
                <img src="../assets/images/bg-bottom.svg" alt="">
            </div>
            <!-- ***** Wellcome Area Background End ***** -->

            <!-- ***** Wellcome Area Content Start ***** -->
            <div class="welcome-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 align-self-center">
                            <h1>{{ $t('home.checkOut') }}</h1>
                            <p>{{ $t('home.we') }}</p>
                            <a class="btn-white-line" href="/work">{{ $t('home.works') }}</a>
                        </div>
                        <div class="offset-lg-1 col-lg-5 col-md-12 col-sm-12 col-xs-12 align-self-center">
                            <div class="apps">
                                <div class="container-fluid">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-3 col-sm-3 col-3" v-for="app in apps">
                                            <a :href="app.url" class="app-item">
                                                <div class="icon">
                                                    <img :src="'/images/icons/apps/2x/' + app.id + '.png'"
                                                         :srcset="'images/icons/apps/4x/' + app.id + '.png 4x'"
                                                         class="img-fluid" alt="">
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ***** Wellcome Area Content End ***** -->
        </section>
        <!-- ***** Wellcome Area End ***** -->

        <Team/>

        <!-- ***** Counter Parallax Start ***** -->
        <section class="parallax">
            <div class="parallax-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="count-item">
                                <strong>250000</strong>
                                <span v-html="$t('home.users')"></span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="count-item">
                                <strong>7</strong>
                                <span v-html="$t('home.mobile')"></span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="count-item">
                                <strong>4.2</strong>
                                <span v-html="$t('home.average')"></span>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6 col-sm-12">
                            <div class="count-item">
                                <strong>3</strong>
                                <span v-html="$t('home.years')"></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import Team from '../components/Team'
    import appsData from '../assets/data/apps';

    export default {
        components: {
            Team
        },
        data() {
            return {
                apps: appsData
            }
        },

        mounted() {
            if($('.count-item').length){
                $('.count-item strong').counterUp({
                    delay: 10,
                    time: 1000
                });
            }
        }
    }
</script>

<style>
    .welcome-bg {
        background-image: url("../assets/images/photos/welcome.jpg");
    }
</style>
