const data = [
    {
        id: 1,
        name: 'Ping',
        desc: 'This app is a software utility used to test the reachability of a host on an IP',
        url: '/ping',
        gplay_url: 'https://play.google.com/store/apps/details?id=ru.otdr.ping',
        stars: 5,
        review: 1366,

    },
    {
        id: 2,
        name: 'WhatExif',
        desc: 'WhatExif is a modern and simple tool that shows and edits the Exif information of your pictures',
        url: '/exif',
        gplay_url: 'https://play.google.com/store/apps/details?id=dev.appteka.exif',
        stars: 3,
        review: 21,

    },
    {
        id: 3,
        name: 'DeviceInfo',
        desc: 'Full Android Device information: Device ID, Device manufacturer, Device name, GSF, IMEI, MAC, RAM, CPU, Root and other',
        url: '/deviceinfo',
        gplay_url: 'https://play.google.com/store/apps/details?id=ru.otdr.deviceinfo',
        stars: 3,
        review: 41,
    },
    {
        id: 4,
        name: 'NanoJurist',
        desc: 'Cards with the most frequent legal situations in life',
        url: '/nanojurist',
        gplay_url: 'https://play.google.com/store/apps/details?id=com.appsteka.nanojurist',
        stars: 5,
        review: 2,
    },
    {
        id: 5,
        name: 'DaysTo',
        desc: 'DaysTo - Countdown of days before holidays, weddings or other big events',
        url: '/daysto',
        gplay_url: 'https://play.google.com/store/apps/details?id=com.appsteka.daysto',
        stars: 5,
        review: 7,
    },
    {
        id: 6,
        name: 'IPConfig',
        desc: 'Shows the current network configuration: assigned IP, DHCP, Gateway, Wi-Fi and Wlan.',
        url: '/ipconfig',
        gplay_url: 'https://play.google.com/store/apps/details?id=com.appsteka.ipconfig',
        stars: 5,
        review: 2,
    },
    {
        id: 7,
        name: 'Traceroute',
        desc: 'Traceroute is a network diagnostic tool used to track pathway taken by a packet on an IP network from source to destination',
        url: '/traceroute',
        gplay_url: 'https://play.google.com/store/apps/details?id=com.appsteka.traceroute',
        stars: 5,
        review: 5,
    }
];

export default data