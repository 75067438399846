import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueYandexMetrika from 'vue-yandex-metrika'
import { i18n } from './plugins/i18n'

Vue.config.productionTip = false

Vue.use(VueYandexMetrika, {
    id: 62683153,
    router: router,
    env: process.env.NODE_ENV,
    clickmap:true,
    trackLinks:true,
    accurateTrackBounce:true,
    webvisor:true
})

new Vue({
    router,
    i18n,
    render: h => h(App),
    data: {
        title: 'appsteka | Mobile App Development'
    }
}).$mount('#app')
