<template>
    <div>
        <section class="page">
            <!-- ***** Page Top Start ***** -->
            <BreadCrumb :links="links" :active="active" :page="page"/>
            <!-- ***** Page Top End ***** -->

            <!-- ***** Page Content Start ***** -->
            <div class="page-bottom pbottom-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12" v-for="app in apps">
                            <div class="page-app">
                                <div class="icon">
                                    <img :src="'/images/icons/apps/2x/' + app.id + '.png'"
                                         :srcset="'images/icons/apps/4x/' + app.id + '.png 4x'"
                                         class="img-fluid" alt="">
                                </div>
                                <div class="app-content">
                                    <h5 class="title">{{app.name}}</h5>
                                    <ul class="stars">
                                        <li v-for="star in [1,2,3,4,5]">
                                            <i class="fa fa-star" :class="!(star > app.stars) ? 'active' : ''"></i>
                                        </li>
                                        <li>{{'(' + app.review + ')'}}</li>
                                    </ul>
                                    <div class="text">
                                        {{ $t(`ourWork.${app.name}`) }}
                                    </div>
                                    <a :href="app.url" class="btn-primary-line">{{ $t('ourWork.details') }}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ***** Page Content End ***** -->
        </section>
    </div>
</template>

<script>
    import BreadCrumb from '../components/BreadCrumb';
    import appsData from '../assets/data/apps';

    export default {
        name: 'OurWork',
        components: {
            BreadCrumb
        },
        data() {
            return {
                links: [
                    {
                        name: 'Home',
                        href: '/'
                    }
                ],
                page: 'article',
                active: 'Our Work',
                apps: appsData
            }
        }
    }
</script>

<style>

</style>
