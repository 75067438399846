<template>
    <div id="app">
        <Preloader/>
        <Header/>
        <router-view/>
        <Footer/>
    </div>
</template>

<script>
    import Header from './components/Header'
    import Footer from './components/Footer'
    import Preloader from './components/Preloader'
    export default {
        components: {
            Header,
            Footer,
            Preloader
        },

        mounted() {
            window.sr = new scrollReveal();
            if(localStorage.lang)
                this.$i18n.locale = localStorage.lang
        }
    }
</script>

<style lang="scss">
    @import './assets/css/style.css';
</style>
