<template>
    <div>
        <section class="page">
            <!-- ***** Page Top Start ***** -->
            <BreadCrumb :links="links" :active="active" :page="page"/>
            <!-- ***** Page Top End ***** -->

            <!-- ***** Page Content Start ***** -->
            <div class="page-bottom pbottom-70">
                <div class="container">
                    <div class="row">
                        <!-- ***** Content Start ***** -->
                        <div class="col-lg-8 col-md-12 col-sm-12" v-html="$t('privacy.full')"/>
                            <!-- ***** Gallery Start ***** -->
                        <!-- ***** Content End ***** -->

                        <!-- ***** Aside Start ***** -->
                        <div class="col-lg-4 col-md-12 col-sm-12">
                            <aside class="default-aside">
                                <div class="sidebar">
                                    <div class="home-services-item box active">
                                        <h5 class="services-title">{{ $t('privacy.questions') }}</h5>
                                        <a class="btn-white-line" href="mailto:dev@appsteka.com">{{ $t('contact.contact') }}</a>
                                    </div>
                                </div>
                            </aside>
                        </div>
                        <!-- ***** Aside End ***** -->
                    </div>
                </div>
            </div>
            <!-- ***** Page Content End ***** -->
        </section>
    </div>
</template>

<script>
    import BreadCrumb from '../components/BreadCrumb'

    export default {
        name: 'PrivacyPolicy',
        components: {
            BreadCrumb
        },
        data() {
            return {
                links: [
                    {
                        name: 'Home',
                        href: '/'
                    },
                    {
                        name: 'Privacy',
                        href: '/privacy'
                    }
                ],
                page: 'article',
                active: 'Privacy'
            }
        }
    }
</script>

<style scoped>

</style>
