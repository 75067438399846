<template>
    <div>
        <section class="page">
            <!-- ***** Page Top Start ***** -->
            <BreadCrumb :links="links" :active="active" :page="page"/>
            <!-- ***** Page Top End ***** -->

            <!-- ***** Page Content Start ***** -->
            <div class="page-bottom pbottom-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <a href="/contact" class="home-services-item">
                                <i class="fa fa-connectdevelop"></i>
                                <h5 class="services-title">{{ $t('services.native') }}</h5>
                                <p>Java / Kotlin / Swift</p>
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <a href="/contact" class="home-services-item">
                                <i class="fa fa-cubes"></i>
                                <h5 class="services-title">{{ $t('services.back') }}</h5>
                                <p>Java / Kotlin / Node.js</p>
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <a href="/contact" class="home-services-item">
                                <i class="fa fa-database"></i>
                                <h5 class="services-title">{{ $t('services.data') }}</h5>
                                <p>PostgreSQL / SQLite / CouchDB</p>
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <a href="/contact" class="home-services-item">
                                <i class="fa fa-code"></i>
                                <h5 class="services-title">{{ $t('services.api') }}</h5>
                                <p>Java / Kotlin / Node.js</p>
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <a href="/contact" class="home-services-item">
                                <i class="fa fa-object-ungroup"></i>
                                <h5 class="services-title">{{ $t('services.design') }}</h5>
                                <p>Figma / Sketch / Adobe Photoshop</p>
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <a href="/contact" class="home-services-item">
                                <i class="fa fa-line-chart"></i>
                                <h5 class="services-title">{{ $t('services.monitoring') }}</h5>
                                <p>Firebase / Zabbix / Grafana</p>
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <a href="/contact" class="home-services-item">
                                <i class="fa fa-user-o"></i>
                                <h5 class="services-title">{{ $t('services.profiling') }}</h5>
                                <p>Google Analytics / Firebase / AppMetrica</p>
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <a href="/contact" class="home-services-item">
                                <i class="fa fa-picture-o"></i>
                                <h5 class="services-title">{{ $t('services.graphic') }}</h5>
                                <p>Figma / Sketch / Adobe Photoshop</p>
                            </a>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <a href="/contact" class="home-services-item">
                                <i class="fa fa-clone"></i>
                                <h5 class="services-title">{{ $t('services.mobility') }}</h5>
                                <p>Agile / Scrum / Kanban</p>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ***** Page Content End ***** -->
        </section>
    </div>
</template>

<script>
    import BreadCrumb from '../components/BreadCrumb'

    export default {
        name: 'Services',
        components: {
            BreadCrumb
        },
        data() {
            return {
                links: [
                    {
                        name: 'Home',
                        href: '/'
                    }
                ],
                page: 'article',
                active: 'Services'
            }
        }
    }
</script>

<style scoped>

</style>
