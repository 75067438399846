<template>
    <div>
        <section class="page">
            <!-- ***** Page Top Start ***** -->
            <BreadCrumb :links="links" :active="active"/>
            <!-- ***** Page Top End ***** -->

            <!-- ***** Page Content Start ***** -->
            <div class="page-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="app-single">
                                <!-- ***** App Header Start ***** -->
                                <div class="header">
                                    <div class="row">
                                        <div class="col-lg-1 col-md-2 col-sm-12">
                                            <div class="icon">
                                                <img src="../assets/images/icons/apps/1x/2.png"
                                                     srcset="../assets/images/icons/apps/2x/2.png 2x, ../assets/images/icons/apps/4x/2.png 4x"
                                                     class="img-fluid" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-4 col-sm-12">
                                            <div class="title">
                                                <h2>{{ $t('apps.exif.exif') }}</h2>
                                                <ul class="stars">
                                                    <li><i class="fa fa-star active"></i></li>
                                                    <li><i class="fa fa-star active"></i></li>
                                                    <li><i class="fa fa-star active"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li>(16)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="download-buttons">
                                                <!--<a href="#"><img src="assets/images/download-apple-store.svg" alt=""></a>-->
                                                <a href="https://play.google.com/store/apps/details?id=dev.appteka.exif" target="_blank"><img src="images/download-google-play.svg" alt=""></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ***** App Header End ***** -->

                                <!-- ***** App Description Start ***** -->
                                <div class="item">
                                    <div class="icon">
                                        <i class="fa fa-file-text-o"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="title">{{ $t('apps.description') }}</h5>
                                        <div class="text">
                                            <!--<p>Donec cursus, tortor vel efficitur finibus, velit nisl iaculis lorem, a rhoncus erat risus id massa. Nulla blandit, eros a pretium malesuada, quam elit pretium nunc, eget placerat ligula quam eget urna. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>-->
                                            <!--<p>Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Fusce egestas nisi mauris, mattis mollis nisl malesuada ac. Ut feugiat, velit ac dictum tempor, nibh massa porttitor enim, vel accumsan nunc ligula eu eros. Vivamus ut mauris consectetur, porta quam eget, placerat urna.</p>-->
                                            <p>{{ $t('apps.exif.whatIs') }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- ***** App Description End ***** -->

                                <!-- ***** Screenshot Start ***** -->
                                <AppScreenshots :links="screenshotsLinks"/>
                                <!-- ***** Screenshot End ***** -->

                                <!-- ***** Version History Start ***** -->
                                <div class="item">
                                    <div class="icon">
                                        <i class="fa fa-file-code-o"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="title">{{ $t('apps.version') }}</h5>
                                        <div class="text">
                                            <div class="version">
                                                <h6>1.6</h6>
                                                <ul>
                                                    <li>{{ $t('apps.support', { version: '10' }) }}</li>
                                                    <li>{{ $t('apps.exif.share') }}</li>
                                                </ul>
                                            </div>
                                            <div class="version">
                                                <h6>1.5</h6>
                                                <ul>
                                                    <li>{{ $t('apps.exif.editing') }}</li>
                                                </ul>
                                            </div>
                                            <div class="version">
                                                <h6>1.4</h6>
                                                <ul>
                                                    <li>{{ $t('apps.exif.redesign') }}</li>
                                                </ul>
                                            </div>
                                            <div class="version">
                                                <h6>1.4</h6>
                                                <ul>
                                                    <li>{{ $t('apps.exif.clearLink') }}</li>
                                                    <li>{{ $t('apps.exif.linkField') }}</li>
                                                    <li>{{ $t('apps.support', {name: 'Pie', version: '10' }) }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ***** Version History End ***** -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ***** Page Content End ***** -->
        </section>
    </div>
</template>

<script>
    import BreadCrumb from '../components/BreadCrumb'
    import AppScreenshots from '../components/AppScreenshots'

    export default {
        name: 'AppExif',
        components: {
            BreadCrumb,
            AppScreenshots
        },
        data() {
            return {
                links: [
                    {
                        name: 'Home',
                        href: '/'
                    },
                    {
                        name: 'Our Work',
                        href: '/our-work'
                    }
                ],
                active: 'WhatExif',
                screenshotsLinks: [
                    `${require('../assets/images/mockup/exif/whatExif-1.png?v=5')}`,
                    `${require('../assets/images/mockup/exif/whatExif-2.png?v=5')}`,
                    `${require('../assets/images/mockup/exif/whatExif-4.png?v=5')}`,
                    `${require('../assets/images/mockup/exif/whatExif-3.png?v=5')}`
                ]
            }
        }
    }
</script>

<style scoped>

</style>
