<template>
    <div class="cover" :data-image="setDataImg()">
        <div class="page-top">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">

                        <h1 v-if="page === 'article'">{{ $t(`${getTranslate(active)}.${getTranslate(active)}`) }}</h1>
                        <h1 v-else >{{ $t('apps.'+`${getTranslate(active)}.${getTranslate(active)}`) }}</h1>
                    </div>
                    <div class="col-lg-12">
                        <ol class="breadcrumb">
                            <li v-for="(link, index) in links" :key="index">
                                <a :href="link.href">{{ $t(`${getTranslate(link.name)}.${getTranslate(link.name)}`) }}</a>
                            </li>
                            <li class="active" v-if="page === 'article'">{{$t(`${getTranslate(active)}.${getTranslate(active)}`)}}</li>
                            <li class="active" v-else>{{ $t('apps.'+`${getTranslate(active)}.${getTranslate(active)}`) }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'BreadCrumb',
        props: {
            links: {
                type: Array,
                required: true
            },
            active: {
                type: String,
                required: true
            },
            page: {
                type: String,
                required: false
            }
        },

        methods: {
            setDataImg() {
                return require('../assets/images/photos/parallax.jpg')
            },
            getTranslate(link) {
                return (link[0].toLowerCase() + link.slice(1)).replace(/\s+/g, '')
            }
        }
    }
</script>

<style scoped>

</style>
