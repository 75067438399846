<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-12 col-sm-12">
                    <img src="../assets/images/logo/black-135-35.svg" class="logo" alt="">
                    <div class="text">{{ $t('footer.cookies') }}</div>
                    <div class="text">&nbsp;</div>
                    <div class="text"><a href="/policy">{{ $t('privacy.header') }}</a></div>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-6">
                    <h5>{{ $t('footer.links') }}</h5>
                    <ul class="footer-nav">
                        <li><a href="/work"><i class="fa fa-angle-right"></i><span>{{ $t('ourWork.ourWork') }}</span></a></li>
                        <li><a href="/about"><i class="fa fa-angle-right"></i><span>{{ $t('footer.aboutUs') }}</span></a></li>
                        <li><a href="/services"><i class="fa fa-angle-right"></i><span>{{ $t('footer.services') }}</span></a></li>
                        <li><a href="/policy"><i class="fa fa-angle-right"></i><span>{{ $t('privacy.header') }}</span></a></li>
                        <li><a href="/contact"><i class="fa fa-angle-right"></i><span>{{ $t('footer.contact') }}</span></a></li>
                    </ul>
                </div>
                <div class="col-lg-2 col-md-4 col-sm-6 col-6">
                    <h5>Latest Apps</h5>
                    <ul class="footer-nav">
                        <li><a href="/ping"><i class="fa fa-angle-right"></i><span>Ping</span></a></li>
                        <li><a href="/exif"><i class="fa fa-angle-right"></i><span>WhatExif</span></a></li>
                        <li><a href="/deviceinfo"><i class="fa fa-angle-right"></i><span>DeviceInfo</span></a></li>
                        <li><a href="/nanojurist"><i class="fa fa-angle-right"></i><span>NanoJurist</span></a></li>
                        <li><a href="/daysto"><i class="fa fa-angle-right"></i><span>DaysTo</span></a></li>
                    </ul>
                </div>
                <div class="col-lg-3 col-md-4 col-sm-12">
                    <h5>Contact Us</h5>
                    <div class="address">
                        <!--<p>Smolensk, Russia, 214000 <br>Prigirodnaya, 7</p>-->
                        <!--<p>+7 (999) 110-1234</p>-->
                        <p><span>E-Mail:</span><a href="mailto:dev@appsteka.com">dev@appsteka.com</a></p>
                        <p></p>
                        <ul class="social">
                            <li><a href="https://www.facebook.com/appsteka/"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="https://www.instagram.com/appsteka/"><i class="fa fa-instagram"></i></a></li>
                            <li><a href="https://twitter.com/appsteka_dev"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="https://www.linkedin.com/company/appsteka/"><i class="fa fa-linkedin"></i></a></li>
                            <li><a href="https://github.com/appsteka/"><i class="fa fa-github"></i></a></li>
                            <li><a href="https://t.me/appsteka"><i class="fa fa-telegram"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <p class="copyright">{{ `© 2018-${new Date().getFullYear()} appsteka. All Rights Reserved.` }}</p>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        name: 'Footer',
    }
</script>

<style>

</style>
