<template>
    <div>
        <section class="page">
            <!-- ***** Page Top Start ***** -->
            <BreadCrumb :links="links" :active="active" :page="page"/>
            <!-- ***** Page Top End ***** -->

            <!-- ***** Page Content Start ***** -->
            <div class="page-bottom">
                <div class="container">
                    <div class="row">
                        <!-- ***** Contact Text Start ***** -->
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <h5 class="mbottom-30">{{ $t('contact.getInTouch') }}</h5>
                            <div class="contact-text">
                                <p>{{ $t('contact.suggestion') }}</p>
                                <p>{{ $t('contact.contactingUs') }}</p>
                            </div>
                        </div>
                        <!-- ***** Contact Text End ***** -->

                        <!-- ***** Contact Form Start ***** -->
                        <div class="col-lg-8 col-md-6 col-sm-12">
                            <div class="contact-form" v-if="!formState">
                                <div class="row">
                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <input type="text" :placeholder="$t('contact.fullName')" v-model="user.name" :class="[validContact.name ? '' : 'input-error']">
                                    </div>
                                    <div class="col-lg-6 col-md-12 col-sm-12">
                                        <input type="text" :placeholder="$t('contact.eMail')" v-model="user.email" :class="[validContact.email ? '' : 'input-error']">
                                    </div>
                                    <div class="col-lg-12">
                                        <textarea :placeholder="$t('contact.yourMessage')" v-model="user.message" :class="[validContact.message ? '' : 'input-error']"></textarea>
                                    </div>
                                    <div class="col-lg-12">
                                        <button class="btn-primary-line" @click="sendContact" :disabled="sending">{{ sending ? '...SENDING' :  'SEND'}}</button>
                                    </div>
                                </div>
                            </div>

                            <div class="form-state" v-else>
                                <div class="row form-state-success" v-if="formState === 'success'">
                                    <div class="col-sm-12">
                                        <i class="fa fa-check-circle-o" aria-hidden="true"></i> {{ $t('contact.messageSent') }}
                                    </div>
                                </div>
                                <div class="row form-state-failed" v-else>
                                    <i class="fa fa-times-circle-o" aria-hidden="true"></i> {{ $t('contact.sendingFailed') }}
                                </div>
                            </div>
                        </div>
                        <!-- ***** Contact Form End ***** -->
                    </div>
                </div>
            </div>
            <!-- ***** Page Content End ***** -->
        </section>
    </div>
</template>

<script>
    import BreadCrumb from '../components/BreadCrumb'
    import axios from 'axios';

    export default {
        name: 'Contact',
        components: {
            BreadCrumb
        },
        data() {
            return {
                links: [
                    {
                        name: 'Home',
                        href: '/'
                    }
                ],
                page: 'article',
                active: 'Contact Us',
                user: {
                    name: null,
                    email: null,
                    message: null
                },
                validContact: {
                    name: true,
                    email: true,
                    message: true
                },
                sending: false,
                formState: null
            }
        },

        methods: {
            sendContact() {
                if(this.isContactValid()) {
                    this.sending = true
                    axios.post('https://formspree.io/f/xeqpjjyz', {
                        name: this.user.name,
                        from: this.user.email,
                        message: this.user.message
                    }).then(resp => {
                        if(resp.status === 200) {
                            this.formState = 'success'
                            this.resetData()
                        } else {
                            this.formState = 'failed'
                        }
                    }).finally(() => this.sending = false)
                }
            },

            isContactValid() {
                if(!this.user.name)
                    this.validContact.name = false
                else
                    this.validContact.name = true
                if(!this.user.email)
                    this.validContact.email = false
                else
                    this.validContact.email = true
                if(!this.user.message)
                    this.validContact.message = false
                else
                    this.validContact.message = true
                if(this.validContact.name === false || this.validContact.email === false || this.validContact.message === false)
                    return false
                else
                    return true

            },

            resetData() {
                this.user = {
                    name: null,
                    email: null,
                    message: null
                }

                this.validContact = {
                    name: true,
                    email: true,
                    message: true
                }
            }
        }
    }
</script>

<style scoped>
    .btn-primary-line:focus {
        outline: none !important;
    }

    .input-error {
        border-color: red;
    }

    .form-state {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    .form-state-success  i {
        color: forestgreen;
    }

    .form-state-failed  i {
        color: red;
    }
</style>
