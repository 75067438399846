<template>
    <div class="item">
        <div class="icon">
            <i class="fa fa-file-image-o"></i>
        </div>
        <div class="content">
            <h5 class="title">Screenshots</h5>
            <div class="row gallery">
                <div class="col-lg-3 col-md-6 col-sm-6 col-xs-12"
                    v-for="(link, index) in links" :key="index">
                    <a class="gallery-item" :href="link" :title="`Screenshot ${index+1}`">
                        <img :src="link" class="img-fluid" alt="">
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'AppScreenshots',
        props: {
            links: {
                type: Array,
                required: true
            }
        },

        methods: {
            showImg() {
                if($('.gallery').length && $('.gallery-item').length){
                    $('.gallery-item').magnificPopup({
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        zoom: {
                            enabled: true,
                            duration: 300,
                            easing: 'ease-in-out',
                        }
                    });
                }

                if($('.page-gallery').length && $('.page-gallery-wrapper').length){
                    $('.page-gallery').imgfix({
                        scale: 1.1
                    });

                    $('.page-gallery').magnificPopup({
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        zoom: {
                            enabled: true,
                            duration: 300,
                            easing: 'ease-in-out',
                        }
                    });
                }
            }
        },

        mounted() {
            this.showImg()
        }
    }
</script>

<style scoped>

</style>
