<template>
    <header class="header-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <nav class="main-nav">
                        <!-- ***** Logo Start ***** -->
                        <a href="/" class="logo">
                            <img src="../assets/images/logo/white-135-35.svg" class="light-logo" alt="Appsteka"/>
                            <img src="../assets/images/logo/black-135-35.svg" class="dark-logo" alt="Appsteka"/>
                        </a>
                        <!-- ***** Logo End ***** -->

                        <!-- ***** Menu Start ***** -->
                        <ul class="nav">
                            <li><a href="/">{{ $t('home.home').toUpperCase() }}</a></li>
                            <li><a href="/work">{{ $t('ourWork.ourWork').toUpperCase() }}</a></li>
                            <li><a href="/about">{{ $t('aboutUs.aboutUs').toUpperCase() }}</a></li>
                            <li><a href="/services">{{ $t('services.services').toUpperCase() }}</a></li>
                            <li><a href="https://srsdt.ru/" target="_blank">{{ $t('games.games').toUpperCase() }}</a></li>
                            <li><a href="/contact" class="btn-nav-line">{{ $t('footer.contact').toUpperCase() }}</a></li>
                            <li class="secret"><a class="btn-link" @click="switchLang()"> {{this.$i18n.locale === 'ru' ? 'EN' : 'RU'}}</a></li>
                        </ul>
                        <a class="menu-trigger" @click="menuToggle()">
                            <span>Menu</span>
                        </a>
                        <!-- ***** Menu End ***** -->
                    </nav>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: 'Header',

        methods: {
            menuToggle() {
                $(this).toggleClass('active');
                $('.header-area .nav').slideToggle(200);
            },

            scrollNavBar() {
                var width = $(window).width();
                if(width > 991) {
                    var scroll = $(window).scrollTop();
                    if (scroll >= 30) {
                        $(".header-area").addClass("header-sticky");
                        $(".header-area .dark-logo").css('display', 'block');
                        $(".header-area .light-logo").css('display', 'none');
                    }else{
                        $(".header-area").removeClass("header-sticky");
                        $(".header-area .dark-logo").css('display', 'none');
                        $(".header-area .light-logo").css('display', 'block');
                    }
                }
            },

            setScrollListner() {
                $(window).on('scroll', () => {
                    this.scrollNavBar()
                });
            },

            setLang(lang) {
                this.$i18n.locale = lang;
                localStorage.setItem('lang', lang);
            },
            switchLang() {
                let lng = this.$i18n.locale;
                if (lng === 'ru') {
                    this.setLang('en');
                } else {
                    this.setLang('ru');
                }
            }
        },

        mounted() {
            this.setScrollListner()
        }
    }
</script>

<style>
 .secret {
     visibility: hidden;
 }
</style>
