<template>
    <div class="loader-wrapper">
        <div class="center">
            <div class="dot dot-one"></div>
            <div class="dot dot-two"></div>
            <div class="dot dot-three"></div>
            <div class="dot dot-four"></div>
            <div class="dot dot-five"></div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Preloader',
        methods: {
            LoadFunction() {
                // Page loading animation
                $(window).on('load', function() {
                    if($('.cover').length){
                        $('.cover').parallax({
                            imageSrc: $('.cover').data('image'),
                            zIndex: '1'
                        });
                    }

                    $(".loader-wrapper").animate({
                        'opacity': '0'
                    }, 600, function(){
                        setTimeout(function(){
                            // Parallax init
                            if($('.parallax').length){
                                $('.parallax').parallax({
                                    imageSrc: `${require('../assets/images/photos/parallax.jpg?v=4')}`,
                                    zIndex: '1'
                                });
                            }
                            $(".loader-wrapper").css("visibility", "hidden").fadeOut();
                        }, 300);
                    });
                });
            }
        },

        mounted() {
            this.LoadFunction()
        }
    }
</script>

<style>

</style>
