<template>
    <div>
        <section class="page">
            <!-- ***** Page Top Start ***** -->
            <BreadCrumb :links="links" :active="active" :page="page"/>
            <!-- ***** Page Top End ***** -->

            <!-- ***** Page Content Start ***** -->
            <div class="page-bottom pbottom-70">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <p>{{ $t('aboutUs.smallTeam') }}</p>
                            <!-- ***** Gallery Start ***** -->
                            <div class="row page-gallery-wrapper">
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <a :href="require('../assets/images/photos/about/work.jpg')" class="page-gallery" title="About Us">
                                        <img :src="require('../assets/images/photos/about/work.jpg')" alt="">
                                    </a>
                                </div>
                                <div class="col-lg-4 col-md-8 col-sm-12">
                                    <div class="row">
                                        <div class="col-lg-12 col-md-6">
                                            <a :href="require('../assets/images/photos/about/code.jpg')" class="page-gallery small" title="About Us">
                                                <img :src="require('../assets/images/photos/about/code.jpg')" alt="">
                                            </a>
                                        </div>
                                        <div class="col-lg-12 col-md-6">
                                            <a :href="require('../assets/images/photos/about/mac.jpg')" class="page-gallery small" title="About Us">
                                                <img :src="require('../assets/images/photos/about/mac.jpg')" alt="">
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-4 col-sm-12">
                                    <a :href="require('../assets/images/photos/about/keyboard.jpg')" class="page-gallery" title="About Us">
                                        <img :src="require('../assets/images/photos/about/keyboard.jpg')" alt="">
                                    </a>
                                </div>
                            </div>
                            <!-- ***** Gallery End ***** -->
                            <!--<p>Quisque quis faucibus tellus, non tincidunt urna. Pellentesque faucibus aliquet mi, id efficitur nisl aliquam at. Phasellus volutpat lobortis consectetur. Duis efficitur iaculis ipsum ut sodales. Phasellus suscipit lectus eget sapien accumsan, vitae auctor magna suscipit. Ut sagittis mauris auctor dictum feugiat. Duis lectus neque, aliquet quis justo euismod, rutrum vehicula felis. In luctus dui nec pretium gravida. In mauris lorem, accumsan sit amet lacinia non, auctor id dui. </p>-->
                        </div>
                    </div>
                </div>
            </div>
            <!-- ***** Page Content End ***** -->
        </section>

        <!-- ***** Parallax Start ***** -->
        <section class="parallax">
            <div class="parallax-content">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="info">
                                <p>{{ $t('aboutUs.we') }} <span>{{ $t('aboutUs.mobile') }}</span> {{ $t('aboutUs.grow') }}</p>
                                <a class="btn-white-line" href="/contact">{{ $t('contact.contact') }}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- ***** Parallax End ***** -->
    </div>
</template>

<script>
    import BreadCrumb from '../components/BreadCrumb'

    export default {
        name: 'AboutUs',
        components: {
            BreadCrumb
        },
        data() {
            return {
                links: [
                    {
                        name: 'Home',
                        href: '/'
                    }
                ],
                page: 'article',
                active: 'About Us'
            }
        },

        methods: {
            showImg() {
                if($('.gallery').length && $('.gallery-item').length){
                    $('.gallery-item').magnificPopup({
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        zoom: {
                            enabled: true,
                            duration: 300,
                            easing: 'ease-in-out',
                        }
                    });
                }

                if($('.page-gallery').length && $('.page-gallery-wrapper').length){
                    $('.page-gallery').imgfix({
                        scale: 1.1
                    });

                    $('.page-gallery').magnificPopup({
                        type: 'image',
                        gallery: {
                            enabled: true
                        },
                        zoom: {
                            enabled: true,
                            duration: 300,
                            easing: 'ease-in-out',
                        }
                    });
                }
            }
        },

        mounted() {
            this.showImg()
        }
    }
</script>

<style scoped>

</style>
