<template>
    <div>
        <section class="page">
            <!-- ***** Page Top Start ***** -->
            <BreadCrumb :links="links" :active="active"/>
            <!-- ***** Page Top End ***** -->

            <!-- ***** Page Content Start ***** -->
            <div class="page-bottom">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="app-single">
                                <!-- ***** App Header Start ***** -->
                                <div class="header">
                                    <div class="row">
                                        <div class="col-lg-1 col-md-2 col-sm-12">
                                            <div class="icon">
                                                <img src="../assets/images/icons/apps/1x/3.png?v=3" class="img-fluid" alt="">
                                            </div>
                                        </div>
                                        <div class="col-lg-5 col-md-4 col-sm-12">
                                            <div class="title">
                                                <h2>{{ $t('apps.deviceInfo.deviceInfo') }}</h2>
                                                <ul class="stars">
                                                    <li><i class="fa fa-star active"></i></li>
                                                    <li><i class="fa fa-star active"></i></li>
                                                    <li><i class="fa fa-star active"></i></li>
                                                    <li><i class="fa fa-star active"></i></li>
                                                    <li><i class="fa fa-star"></i></li>
                                                    <li>(35)</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-lg-6 col-md-6 col-sm-12">
                                            <div class="download-buttons">
                                                <!--<a href="#"><img src="assets/images/download-apple-store.svg" alt=""></a>-->
                                                <a href="https://play.google.com/store/apps/details?id=ru.otdr.deviceinfo" target="_blank"><img src="images/download-google-play.svg" alt=""></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ***** App Header End ***** -->

                                <!-- ***** App Description Start ***** -->
                                <div class="item">
                                    <div class="icon">
                                        <i class="fa fa-file-text-o"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="title">{{ $t('apps.description') }}</h5>
                                        <div class="text">
                                            <p>{{ $t('apps.deviceInfo.information') }}</p>
                                            <div v-html="$t('apps.deviceInfo.features')"/>
                                        </div>
                                    </div>
                                </div>
                                <!-- ***** App Description End ***** -->

                                <!-- ***** Screenshot Start ***** -->
                                <AppScreenshots :links="screenshotsLinks"/>
                                <!-- ***** Screenshot End ***** -->

                                <!-- ***** Version History Start ***** -->
                                <div class="item">
                                    <div class="icon">
                                        <i class="fa fa-file-code-o"></i>
                                    </div>
                                    <div class="content">
                                        <h5 class="title">{{ $t('apps.version') }}</h5>
                                        <div class="text">
                                            <div class="version">
                                                <h6>1.7</h6>
                                                <ul>
                                                    <li>{{ $t('apps.deviceInfo.identifier') }}</li>
                                                    <li>{{ $t('apps.deviceInfo.admob') }}</li>
                                                </ul>
                                            </div>
                                            <div class="version">
                                                <h6>1.6</h6>
                                                <ul>
                                                    <li>{{ $t('apps.deviceInfo.gyro') }}</li>
                                                    <li>{{ $t('apps.deviceInfo.design') }}</li>
                                                </ul>
                                            </div>
                                            <div class="version">
                                                <h6>1.5</h6>
                                                <ul>
                                                    <li>{{ $t('apps.deviceInfo.corrected') }}</li>
                                                    <li>{{ $t('apps.deviceInfo.interface') }}</li>
                                                    <li>{{ $t('apps.deviceInfo.rare') }}</li>
                                                </ul>
                                            </div>
                                            <div class="version">
                                                <h6>1.4</h6>
                                                <ul>
                                                    <li>{{ $t('apps.deviceInfo.processor') }}</li>
                                                </ul>
                                            </div>
                                            <div class="version">
                                                <h6>1.2</h6>
                                                <ul>
                                                    <li>{{ $t('apps.deviceInfo.GSF') }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- ***** Version History End ***** -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- ***** Page Content End ***** -->
        </section>
    </div>
</template>

<script>
    import BreadCrumb from '../components/BreadCrumb'
    import AppScreenshots from '../components/AppScreenshots'
    export default {
        name: 'AppDeviceInfo',
        components: {
            BreadCrumb,
            AppScreenshots
        },
        data() {
            return {
                links: [
                    {
                        name: 'Home',
                        href: '/'
                    },
                    {
                        name: 'Our Work',
                        href: '/our-work'
                    }
                ],
                active: 'Device Info',
                screenshotsLinks: [
                    `${require('../assets/images/mockup/devinfo/1_get.png')}`,
                    `${require('../assets/images/mockup/devinfo/2_copy.png')}`,
                    `${require('../assets/images/mockup/devinfo/3_find.png')}`,
                    `${require('../assets/images/mockup/devinfo/4_share.png')}`
                ]
            }
        }
    }
</script>

<style scoped>

</style>
