import { render, staticRenderFns } from "./AppDaysTo.vue?vue&type=template&id=54c2815c&scoped=true&"
import script from "./AppDaysTo.vue?vue&type=script&lang=js&"
export * from "./AppDaysTo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c2815c",
  null
  
)

export default component.exports