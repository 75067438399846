import { render, staticRenderFns } from "./AppNanoJurist.vue?vue&type=template&id=0b518332&scoped=true&"
import script from "./AppNanoJurist.vue?vue&type=script&lang=js&"
export * from "./AppNanoJurist.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b518332",
  null
  
)

export default component.exports