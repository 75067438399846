import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import OurWork from '../views/OurWork.vue'
import AppPing from '../views/AppPing'
import AppExif from '../views/AppExif'
import AppDeviceInfo from '../views/AppDeviceInfo'
import AppNanoJurist from '../views/AppNanoJurist'
import AppDaySto from '../views/AppDaysTo'
import AppIpConfig from '../views/AppIpConfig'
import AppTraceroute from '../views/AppTraceroute'
import AboutUs from '../views/AboutUs'
import Services from '../views/Services'
import Contact from '../views/Contact'
import PrivacyPolicy from '../views/PrivacyPolicy'

Vue.use(VueRouter)

    const routes = [
        {
            path: '/',
            name: 'Home',
            component: Home
        },
        {
          path: '/work',
          name: 'OurWork',
          component: OurWork
        },
        {
           path: '/ping',
           name: 'AppPing',
           component: AppPing
        },
        {
            path: '/exif',
            name: 'AppExif',
            component: AppExif
        },
        {
            path: '/deviceinfo',
            name: 'AppDeviceInfo',
            component: AppDeviceInfo
        },
        {
            path: '/nanojurist',
            name: 'AppNanoJurist',
            component: AppNanoJurist
        },
        {
            path: '/daysto',
            name: 'AppDaySto',
            component: AppDaySto
        },
        {
            path: '/ipconfig',
            name: 'AppIpConfig',
            component: AppIpConfig
        },
        {
            path: '/traceroute',
            name: 'AppTraceroute',
            component: AppTraceroute
        },
        {
            path: '/about',
            name: 'AboutUs',
            component: AboutUs
        },
        {
            path: '/services',
            name: 'Services',
            component: Services
        },
        {
            path: '/contact',
            name: 'Contact',
            component: Contact
        },
        {
            path: '/policy',
            name: 'PrivacyPolicy',
            component: PrivacyPolicy
        }
    ]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
