<template>
    <section class="section pbottom-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="center-heading">
                        <h2 class="section-title">{{ $t('team.ourTeam') }}</h2>
                    </div>
                </div>
                <div class="offset-lg-3 col-lg-6">
                    <div class="center-text">
                        <p>{{ $t('team.properly') }}</p>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div class="team-item">
                        <div class="header">
                            <div class="img">
                                <img src="../assets/images/photos/team/dimon.png" alt="">
                            </div>
                            <div class="info">
                                <strong>{{ $t('team.dimon') }}</strong>
                                <span>{{ $t('team.developer') }}</span>
                            </div>
                        </div>
                        <div class="body">
                            {{ $t('team.mobile') }}
                        </div>
                        <ul class="social">
                            <li><a href="https://www.facebook.com/profile.php?id=100007746853243"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="https://twitter.com/Dmitriy_Matrix"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="https://t.me/rusakovdmitriy"><i class="fa fa-telegram"></i></a></li>
                            <li><a href="https://vk.com/rusakov_dmitriy"><i class="fa fa-vk"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div class="team-item">
                        <div class="header">
                            <div class="img">
                                <img src="../assets/images/photos/team/dimas.png" alt="">
                            </div>
                            <div class="info">
                                <strong>{{ $t('team.dimas') }}</strong>
                                <span>{{ $t('team.developer') }}</span>
                            </div>
                        </div>
                        <div class="body">
                            {{ $t('team.mobile') }}
                        </div>
                        <ul class="social">
                            <li><a href="https://www.facebook.com/dmitry.simonov777"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="https://twitter.com/dmitry_simonov7"><i class="fa fa-twitter"></i></a></li>
                            <li><a href="https://t.me/dmitrysimonov"><i class="fa fa-telegram"></i></a></li>
                            <li><a href="https://vk.com/dmitry.simonov7"><i class="fa fa-vk"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 col-xs-12">
                    <div class="team-item">
                        <div class="header">
                            <div class="img">
                                <img src="../assets/images/photos/team/oleg.png" alt="">
                            </div>
                            <div class="info">
                                <strong>{{ $t('team.oleg') }}</strong>
                                <span>{{ $t('team.manager') }}</span>
                            </div>
                        </div>
                        <div class="body">
                            {{ $t('team.management') }}
                        </div>
                        <ul class="social">
                            <li><a href="https://www.facebook.com/olejack.ru"><i class="fa fa-facebook"></i></a></li>
                            <li><a href="https://www.linkedin.com/in/torshin/"><i class="fa fa-linkedin"></i></a></li>
                            <li><a href="http://t.me/ole_jack"><i class="fa fa-telegram"></i></a></li>
                            <li><a href="https://vk.com/ole_jack"><i class="fa fa-vk"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'Team'
    }
</script>

<style>

</style>
